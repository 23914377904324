@import "../../../../styles/variables";

.component {
  width: 750px;

  .templateIntro {
    padding-bottom: 10px;
  }

  .sectionHeading {
    padding: 0 0 5px 0;
    font-weight: 600;
  }

  .warningCallout {
    margin-bottom: 10px;
  }

  .row {
    display: block;
    position: relative;
    border-radius: @border-radius;
    border: 1px solid @border-color-1;
    //padding-left: 250px;
    background: @white;
    min-height: 90px;
    margin-bottom: 10px;

    //.thumbnail {
    //  position: absolute;
    //  top: 0;
    //  left: 0;
    //  vertical-align: top;
    //  width: 250px;
    //
    //  border-top-left-radius: @border-radius;
    //  border-bottom-left-radius: @border-radius;
    //  overflow: hidden;
    //
    //  img {
    //    width: 100%;
    //    opacity: 0.8;
    //  }
    //}
  }

  .selectedRow {
    display: block;
    position: relative;
    border-radius: @border-radius;
    border: 3px solid @border-color-1;
    //padding-left: 250px;
    background: @white;
    min-height: 90px;
    margin-bottom: 10px;
  }

  .content {
    padding: 10px;

    .title {
      font-weight: 600;
      //font-size: 20px;
      //line-height: 20px;
      padding-bottom: 5px;
    }

    .description {
      margin-bottom: 15px;
      line-height: 1.5em;
    }
  }

  .checkboxContainer {
    position: absolute;
    top: 10px;
    right: 0;
  }

  .selectStore {
    height: 60px;
  }

  .callout {
    margin-bottom: 30px;
  }

  .emptyPlaceholder {
    height: 60px;
  }

  .createWorkbookButton {
    margin: auto;
  }
}
