import React, { Component } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import asyncJS from 'async';
import { Button, Callout, Classes, Dialog, Intent, Checkbox } from '@blueprintjs/core';
import * as styles from './addShopifyLinkedWorkbookDialog.module.less';
import API from '../../../../config';
import Logger from '../../../../models/logger';
// import blankWorkbookImage from '../../../../assets/images/new-workbook-empty.png';
// import templateWorkbookImage from '../../../../assets/images/new-workbook-template.png';
import AxiosClient from '../../../../lib/AxiosClient';

const customSelectStyles = {
  control: provided => ({
    ...provided,
    minHeight: '39px',
    background: '#f8f7f7',
    fontWeight: '600',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '39px',
  }),
  clearIndicator: provided => ({
    ...provided,
    padding: '5px',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: '5px',
  }),
};

/**
 * A dialog that allows the user to add a new Workbook
 */
class AddShopifyLinkedWorkbookDialog extends Component {
  constructor(props) {
    super(props);

    this.parentFunctions = {
      closeAddShopifyWorkbookDialog: props.closeAddShopifyWorkbookDialog,
      createWorkbook: props.createWorkbook,
    };

    /* Create our logger */
    this.logger = new Logger('AddShopifyLinkedWorkbookDialog');

    this.state = {
      storesSubscriptions: props.storesSubscriptions || {},
      storesAppsInstalled: props.storesAppsInstalled || {},
      selectedShopifyStore: null,
      connectedShopifyStores: [],
      connectedShopifyStoresSelectOptions: [],
      shopifyStoresStillDownloadingData: [], // List of store urls that are still doing their initial data download into Mixtable
      working: false,
      selectedTemplate: null,
    };

    this.shopifyWorkbookTemplates = {
      basicSection: {
        title: 'Basics',
        templates: [
          {
            id: 'start-from-scratch',
            title: 'Start from Scratch',
            description: 'Create the same, blank workbook that you get in Excel. Best place to start if you are an advanced user and know exactly what Shopify worksheets you\'d like to add.',
          },
        ],
      },
      bulkEditorSection: {
        title: 'Bulk Editor',
        templates: [
          {
            id: 'products-and-collections',
            title: 'Products and Collections',
            description: 'Worksheets for all your products and collections, with the columns folks most often need',
          },
          {
            id: 'orders',
            title: 'Orders',
            description: 'Worksheets for all your orders and order line items',
          },
          {
            id: 'customers',
            title: 'Customers',
            description: 'Worksheets for all your customers',
          },
        ],
      },
      analyticsSection: {
        title: 'Analytics',
        templates: [
          {
            id: 'product-sales',
            title: 'Product sales',
            description: 'Product net order sales, for all time and for each calendar year',
          },
          {
            id: 'product-orders',
            title: 'Product orders',
            description: 'Product order numbers, for all time and for each calendar year',
          },
          {
            id: 'holiday-sales',
            title: 'Holiday sales',
            description: 'Product net sales, for the last few holiday seasons',
          },
        ],
      },
    };
  }

  /** Sets the stores select options */
  setStoresSelectOptions = () => {
    const connectedShopifyStores = this.state.storesSubscriptions ? Object.keys(this.state.storesSubscriptions) : [];
    const connectedShopifyStoresSelectOptions = connectedShopifyStores.map(store => ({ value: store, label: store }));

    if (connectedShopifyStores && connectedShopifyStores.length > 0) {
      this.selectStore(connectedShopifyStores[0]);
    }

    this.setState({
      connectedShopifyStores,
      connectedShopifyStoresSelectOptions,
    });
  }

  /* Checks if all our stores have completed their initial data downloads */
  checkIfInitialStoreDownloadHasCompleted = async () => {
    const newStoresStillDownloadingData = [];

    await asyncJS.eachLimit(this.state.connectedShopifyStores || [], 1, async (storeURL) => {
      try {
        const response = await AxiosClient.getInstance().get(`${API.API_URL}/1.0/integrations/shopify/${storeURL}/downloadStatus/`);

        // console.log(`checking ${storeURL} - response.data: ${JSON.stringify(response.data)}`);

        // If we don't have a response.data, mark store as needs downlad
        if (!response.data) {
          // console.log(`adding ${storeURL} - ${JSON.stringify(response.data)}`);
          newStoresStillDownloadingData.push(storeURL);
        } else if (JSON.stringify(response.data).indexOf('in-progress') > -1) {
          // Check if any entry is "downloading". Do a test by stringifying the JSON and trying to find the "in-progress" string. Simpler than iterating over all the fields
          // and somewhat future-proof in case we decide to add new fields

          // console.log(`adding (2) ${storeURL} - ${JSON.stringify(response.data)}`);
          newStoresStillDownloadingData.push(storeURL);
        }
      } catch (err) {
        this.logger.error('checkIfInitialStoreDownloadHasCompleted', `Error getting sync task count for ${storeURL}: ${err}`);

        /* Show the error to the user */
        // this.parentFunctions.showError(err);
      } finally {
      }
    });

    this.setState({
      shopifyStoresStillDownloadingData: newStoresStillDownloadingData,
    }); // Update the state with the list of stores still downloading data
  }

  async componentDidMount() {
    await this.setStoresSelectOptions();
    await this.checkIfInitialStoreDownloadHasCompleted();
  }

  /**
   * Selects/Deselects template and saves it to the state
   * @param templateId
   * @param value
   */
  selectTemplate = (templateId, value) => {
    this.setState({
      selectedTemplate: value ? templateId : null,
    });
  }

  /**
   * Selects store, sets selectedStoreCanUseAnalytics and nullifies the selected template
   * @param selectedShopifyStore
   */
  selectStore = (selectedShopifyStore) => {
    const selectedStoreCanUseAnalytics = this.state.storesAppsInstalled[selectedShopifyStore] && this.state.storesAppsInstalled[selectedShopifyStore].analytics;

    this.setState({
      selectedShopifyStore,
      selectedStoreCanUseAnalytics,
      selectedTemplate: null,
    });
  }

  render() {
    return (
      <Dialog
        isOpen={true}
        onClose={this.parentFunctions.closeAddShopifyWorkbookDialog}
        // icon="new-layer"
        // title="Create a workbook - How do you want to start?"
        style={{ width: 'fit-content' }}
        {...this.state}>
        <div className={Classes.DIALOG_BODY}>
          <div className={styles.component}>

            <div className={styles.templateIntro}>
              Begin with one of our templates, which include pre-made worksheets featuring the data users most
              frequently need. Each worksheet comes with the most commonly used columns, making it an excellent starting
              point. You can easily add or remove data to fit your needs, but these workbooks provides a solid
              foundation for most users
            </div>

            {Object.keys(this.shopifyWorkbookTemplates).map(sectionKey => (
              <div key={sectionKey}>
                <div className={styles.sectionHeading}>{this.shopifyWorkbookTemplates[sectionKey].title}</div>

                {/* Special case if analytics are not installed */}
                {sectionKey === 'analyticsSection' && !this.state.selectedStoreCanUseAnalytics && <Callout className={styles.warningCallout} intent={Intent.PRIMARY}>
                  <p>If you're interested to join the private beta for Mixtable Analytics and get an early preview, please email us at <a href="mailto:team@mixtable.com">team@mixtable.com</a></p>
                </Callout>}

                {this.shopifyWorkbookTemplates[sectionKey].templates.map(template => (
                  <div key={template.id}>
                    <div className={this.state.selectedTemplate === template.id ? styles.selectedRow : styles.row}>
                      <div className={styles.content}>
                        <div className={styles.title}>
                          {template.title}
                        </div>
                        <div className={styles.description}>
                          {template.description}
                        </div>
                      </div>
                      <Checkbox
                        className={styles.checkboxContainer}
                        onChange={(filterValue) => {
                          this.selectTemplate(template.id, filterValue.target.checked);
                        }}
                        disabled={sectionKey === 'analyticsSection' && !this.state.selectedStoreCanUseAnalytics}
                        checked={this.state.selectedTemplate === template.id}
                      />
                    </div>
                  </div>
                ))}
              </div>
            ))}

            {/* Store selector*/}
            <div className={styles.selectStore}>
              {/* You still need to add a store*/}
              {this.state.connectedShopifyStores.length === 0 &&
                <Callout intent={Intent.WARNING}
                  style={{ paddingTop: '5px', paddingBottom: '5px', paddingLeft: '45px' }}>
                  <div>You need to connect a Shopify store to Mixtable</div>
                </Callout>
              }

              {/* Dropdown showing list of stores*/}
              {this.state.connectedShopifyStores.length > 0 && <div>
                {/* Shopify store selector - show if we have more than one store */}
                {this.state.connectedShopifyStores.length > 1 && <div style={{ marginBottom: '10px' }}>
                  <Select
                    styles={customSelectStyles}
                    placeholder={'Select a Shopify store'}
                    value={_.find(this.state.connectedShopifyStoresSelectOptions, { value: this.state.selectedShopifyStore })}
                    onChange={((filterValue) => {
                      this.selectStore(filterValue.value);
                    })}
                    options={this.state.connectedShopifyStoresSelectOptions}
                  />
                </div>}
              </div>}
            </div>

            <div className={styles.createWorkbookButton}>
              <Button
                large={false}
                intent={Intent.PRIMARY}
                onClick={async () => {
                  this.parentFunctions.createWorkbook(this.state.selectedTemplate, this.state.selectedShopifyStore);
                }}
                disabled={!this.state.selectedTemplate}
                text={'Create Workbook'}
                minimal={false}
                fill={true}
                alignText={'center'}
                loading={this.state.working}
              />
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default AddShopifyLinkedWorkbookDialog;
